import React from "react";
import { NavLink } from "react-router-dom";

import portfolioData from "../data/portfolio.json";

const Portfolio = (props) => {
  return (
    <div className="container">
      <div className="my-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Recent Projects
            </li>
          </ol>
        </nav>
      </div>
      <div className="">
        <div className="section-header">
          <h2 className="title">Recent Projects</h2>
          <p className="section-description">
            {/* Some of our recent projects */}
          </p>
        </div>

        {portfolioData.map((data, key) => (
          <div key={key} className="row pt-3 row-item">
            <div className="col-lg-4 col-md-6 img-hld">
              <a href={data.url} target="_blank" rel="noreferrer">
                <img
                  className=""
                  src={`/assets/images/portfolio/${data.image}`}
                  alt=""
                  width="300"
                  height="300"
                />
              </a>
            </div>
            <div className="col">
              <h3>
                <a href={data.url} target="_blank" rel="noreferrer">
                  {data.name}
                </a>
              </h3>
              <p>{data.description}</p>
              <p>
                <b className="title">Technology:</b>&nbsp;
                {data.technology.map((tech, k) => (
                  <span>
                    <strong>{tech}</strong>{" "}
                  </span>
                ))}
              </p>
              <p className="title">Features</p>
              <ul className="item-list">
                {data.features.map((feature, k) => (
                  <li key={k}>
                    <i className="fa fa-caret-right"></i>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
