import React from "react";
import Slider from "./Slider";
// import { NavLink } from "react-router-dom";
import About from "../pages/About";
import Services from "../pages/Services";
import { NavLink } from "react-router-dom";
// import '../assets/css/style.css';
function Home() {
  return (
    <div>
      <div className="jumbotron col" id="jumbo">
        <div className="jumbo-content pt-1">
          <h1>Seon Technologies</h1>
          <h2>Focus on Quality. No compromise with Security.</h2>
          <h3 style={{ color: "#fff", fontWeight: 700 }}>
            <i className="fa fa-quote-left fa-2x text-light mb-3 px-3"></i>
            Web Application | Mobile Application | Window Application
          </h3>
          <div className="mb-3">
            <a
              className=""
              href="https://wa.me/+919899971734?text=Hi%20Seon%20Technology%20Team."
            >
              <img
                alt="Chat on WhatsApp"
                src="assets/images/WhatsAppButtonGreenSmall.png"
              />
            </a>
            <NavLink className="btn-get-started" to="/contact">
              Request Callback
            </NavLink>
          </div>
        </div>
      </div>
      <About hideHeading={true} />
      <Services />

      <div id="support" className="p-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <h3 className="">Need Assistance !</h3>
            </div>
            <div className="col-lg-3 cta-btn-container text-center">
              <NavLink className="btn-get-started" to="/contact">
                Request Callback
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <Slider />
    </div>
  );
}

export default Home;
