import React from "react";
import "../css/footer.css";

function Footer(props) {
	return (
		<>
			<footer id='footer' className=''>
				<div className='container'>
					<div>
						&copy; Copyright <strong>Seon Technologies</strong>. All
						Rights Reserved
					</div>
				</div>
			</footer>
		</>
	);
}

Footer.defaultProps = {
	name: "Neeraj",
};

export default Footer;
