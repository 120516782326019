import React from "react";
import "../css/slider.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink } from "react-router-dom";

import portfolioData from "../data/portfolio.json";

const Slider = () => {
  return (
    <div id="portfolio" className="p-3">
      <div className="container wow fadeInUp">
        <div className="col block-header">
          <h3 className="block-title mb-3">Recent Projects</h3>
          <p className="section-description">
            {/* {process.env.PUBLIC_URL +
							"assets/js/owlcarousel/owl.theme.default.min.css"} */}
          </p>
        </div>
        <div className="col-sm-12">
          <OwlCarousel
            items={3}
            className="owl-carousel owl-theme slider"
            loop
            nav
            margin={8}
          >
            {portfolioData.map((data, key) => (
              <div key={key} className="img-pl-hld">
                <img
                  className="slider-img"
                  src={`assets/images/portfolio/${data.image}`}
                  alt={data.name}
                />
              </div>
            ))}
          </OwlCarousel>
        </div>
        <div className="col-sm-12 p-3">
          <span className="float-end">
            <NavLink to="/portfolio">More</NavLink>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Slider;
