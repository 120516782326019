import React from "react";
import { NavLink } from "react-router-dom";

const Header = (props) => {
	return (
		<>
			<header id='header' className='fixed-top bg-my-blue'>
				<div className='container'>
					<nav className='navbar navbar-expand-md  navbar-dark1  bg-dark1 '>
						<div className='row w-100'>
							<div className='col-sm-4 logo'>
								<button
									className='navbar-toggler'
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='.navbars'
									aria-controls='navbarText'
									aria-expanded='false'
									aria-label='Toggle navigation'>
									<div cssstyle='padding: 5px 15px 0 0;'>
										<span className='bar'></span>
										<span className='bar'></span>
										<span className='bar'></span>
									</div>
								</button>
								<span className=''>
									<NavLink to='/'>Seon Tech</NavLink>
								</span>
							</div>
							<div className='col '>
								<div
									className='collapse navbar-collapse tp-menu navbars'
									id='navbarCollapse'>
									<ul className='navbar-nav mr-auto mt-2 menu mt-lg-0'>
										<li className='nav-item'>
											<NavLink to='/'>Home</NavLink>
										</li>
										<li>
											<NavLink to='/about'>
												About Us
											</NavLink>
										</li>
										<li>
											<NavLink to='/services'>
												Services
											</NavLink>
										</li>
										<li>
											<NavLink to='/portfolio'>
												Portfolio
											</NavLink>
										</li>
										<li>
											<NavLink
												className='nav-link'
												to='/contact'>
												Contact
											</NavLink>
										</li>
										{/* <li>
											<NavLink
												className='nav-link'
												to='/login'>
												Login
											</NavLink>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
					</nav>
				</div>
			</header>
		</>
	);
};

export default Header;
