import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// import { useState } from "react";
import { NavLink } from "react-router-dom";
import useForm from "../Hooks/useForm";
import ContactValidationRule from "../Hooks/ContactValidationRule";

const Contact = () => {
  const callbackForm = () => {
    submitForm();
  };

  console.log("OK");

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackForm,
    ContactValidationRule,
  );

  const submitForm = async () => {
    // console.log("I am in submit form. ", values);
    // const submitUrl = `http://localhost:5000/contact`;
    const submitUrl = `/api/reactcontact`;
    // const submitUrl = `https://seontechnologies.in/contact`;
    try {
      const res = await fetch(submitUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (res.status === 200) {
        const data = await res.json();
        setApiData(data);
        setShowForm(false);
      } else {
        setApiData({ msg: "Error in processing." });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [apiData, setApiData] = useState({});
  const [showForm, setShowForm] = useState(true);

  // Function to handle onchange of recaptcha
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  // const user = `https://api.github.com/users/hacktivist123/repos`;
  // const user = "https://randomuser.me/api/";

  // const fetchData = async () => {
  // 	try {
  // 		const res = await fetch(user);
  // 		const data = await res.json();
  // 		console.log(data);
  // 		setApiData(data);
  // 	} catch (err) {
  // 		console.log(err);
  // 	}
  // };

  useEffect(() => {
    // fetchData();
    // console.log("I am in inside useEffect");
  }, []);

  return (
    <div className="container">
      <div className="">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="section-header">
            <h2 className="title">Contact Us</h2>
          </div>
        </div>
        <div className="col-sm-4">
          <h5>Office Address</h5>
          <ul className="item-list">
            <li>
              <div className="row">
                <div className="col-sm-1">
                  <i className="fa fa-map-marker"></i>
                </div>
                <div className="col">
                  Seon Technologies
                  <br />
                  III-A, 67, Rachna, Vaishali
                  <br />
                  Ghaziabad, UP-201010
                </div>
              </div>
            </li>
            <li>
              <div className="row">
                <div className="col-sm-1">
                  <i className="fa fa-phone-alt"></i>
                </div>
                <div className="col">+91-98999 71734</div>
              </div>
            </li>
            <li>
              <div className="row">
                <div className="col-sm-1">
                  <i className="fa fa-hands-helping"></i>
                </div>
                <div className="col">
                  24 x 7 Support and Service
                  <br />
                  Email
                  <br />
                  Instant Messenger
                  <br />
                  Voice chat <br />
                  for faster communication
                  <br />
                </div>
              </div>
            </li>
            <li></li>
          </ul>
        </div>
        <div className="col">
          <h2>{apiData.msg}</h2>
          {showForm && (
            <form
              id="frm"
              className="contact-form"
              method="POST"
              noValidate="novalidate"
              onSubmit={handleSubmit}
            >
              <ul>
                <li>
                  <label htmlFor="name">Name</label>

                  <input
                    className={`${errors.name && "error"}`}
                    id="name"
                    name="name"
                    required=""
                    type="text"
                    value={values.name || ""}
                    onChange={handleChange}
                  />
                  <span className="error">
                    {errors.name && <>{errors.name}</>}
                  </span>
                </li>
                <li>
                  <label htmlFor="email">
                    <label htmlFor="email">Email</label>
                  </label>

                  <input
                    className={`${errors.email && "error"}`}
                    id="email"
                    name="email"
                    required=""
                    type="email"
                    value={values.email || ""}
                    onChange={handleChange}
                  />
                  <span className="error">
                    {errors.email && <>{errors.email}</>}
                  </span>
                </li>
                <li>
                  <label htmlFor="mobile">Mobile</label>

                  <input
                    id="mobile"
                    name="mobile"
                    required=""
                    type="text"
                    value={values.mobile || ""}
                    onChange={handleChange}
                  />
                  <span></span>
                </li>
                <li>
                  <label htmlFor="url">Website</label>

                  <input
                    id="website"
                    name="website"
                    type="text"
                    value={values.website || ""}
                    onChange={handleChange}
                  />
                  <span>Your website address (eg: http://www.google.com)</span>
                </li>
                <li>
                  <label htmlFor="bio">Additional Information</label>

                  <textarea
                    className={`${errors.content && "error"}`}
                    cols="10"
                    id="content"
                    name="content"
                    required=""
                    rows="5"
                    value={values.content || ""}
                    onChange={handleChange}
                  ></textarea>
                  <span className="error">
                    {errors.content && <>{errors.content}</>}
                  </span>
                </li>
                <li>
                  <ReCAPTCHA
                    sitekey="6LfrfdsZAAAAAEVZAYkMTYNp0guLHimDL_dsMiJk"
                    onChange={onChange}
                  />
                </li>
                <li>
                  <button type="submit" className="btn btn-lg">
                    {" "}
                    Send
                  </button>
                </li>
              </ul>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
