import React from "react";
import { NavLink } from "react-router-dom";
const Services = () => {
  return (
    <div id="services" className="p-2">
      <div className="container">
        <div className="block-header">
          <h3 className="block-title">Services</h3>
          <p className="block-description">
            We provide a wide range of services, innovative as well as cost
            effective solutions that boost your business by reflecting
            tremendous growth in productivity and higher return of investment.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 wow fadeInUp">
            <div className="box">
              <div className="icon">
                <NavLink to="">
                  <i className="fa fa-desktop fa-4x"></i>
                </NavLink>
              </div>
              <h4 className="title">
                <NavLink to="">Web Development</NavLink>
              </h4>
              <p className="description">
                Every business is unique and so is the business requirements. We
                provide custom programming, database designing, graphics
                designing and internet or intranet based software application
                development
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp">
            <div className="box">
              <div className="icon">
                <NavLink to="">
                  <i className="fa fa-signal fa-4x"></i>
                </NavLink>
              </div>
              <h4 className="title">
                <NavLink to="">Mobile App Development</NavLink>
              </h4>
              <p className="description">
                Specialized in the development of Android App. Fullstack mobile
                app development involves both UI and REST Api development.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp">
            <div className="box">
              <div className="icon">
                <NavLink to="">
                  <i className="fa fa-code fa-4x"></i>
                </NavLink>
              </div>
              <h4 className="title">
                <NavLink to="">Software Development</NavLink>
              </h4>
              <p className="description">
                Every individual is unique in nature and so is the problem. We
                provide custom software development as per your requirements.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp">
            <div className="box">
              <div className="icon">
                <NavLink to="">
                  <i
                    className="fa fa-search-plus fa-4x
                  ico-services"
                  ></i>
                </NavLink>
              </div>
              <h4 className="title">
                <NavLink to="">Search Engine Optimization</NavLink>
              </h4>
              <p className="description">
                Convert your potential visitors into your esteemed customer. We
                understand you do wish to convert your visitors into your
                customers and we can help you convert visitors into customers.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp">
            <div className="box">
              <div className="icon">
                <NavLink to="">
                  <i className="fa fa-chart-line fa-4x"></i>
                </NavLink>
              </div>
              <h4 className="title">
                <NavLink to="">Social Marketing</NavLink>
              </h4>
              <p className="description">
                We provide Marketing services so that your application can reach
                to the designated audience.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp">
            <div className="box">
              <div className="icon">
                <NavLink to="">
                  <i className="fa fa-wifi fa-4x"></i>
                </NavLink>
              </div>
              <h4 className="title">
                <NavLink to="">Web Hosting</NavLink>
              </h4>
              <p className="description">
                We offer high quality infrastructure for your business needs and
                provide Linux based web hosting. We have plans that suits for
                your business needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
