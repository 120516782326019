import React from "react";
import { NavLink } from "react-router-dom";
const About = (props) => {
  return (
    <div id="about" className="p-3">
      <div className="container">
        {/* {props.hideHeading ? (
					""
				) : (
					<h2 className='title text-center'>About Us</h2>
				)} */}
        <div className="row">
          <div className="col-lg-6">
            <h2 className="title">About Us</h2>
            <p>
              Seon Technologies is India based IT solution provider to your
              varying business needs.
            </p>
            <p>
              We are a strong team of highly qualified and innovative IT
              professionals having more than 10 years of experience in Web and
              Mobile Development
            </p>
            <div className="icon-box wow fadeInUp" data-wow-delay="0.2s">
              <h4 className="title">
                <NavLink to="">Objective</NavLink>
              </h4>
              <p className="description">
                Our principle objective is to provide customer satisfaction. We
                work very close to the customer as we are available through IM,
                Phone and Internet telephony for faster communication which
                results in higher throughput and quick solution to the problem
              </p>
            </div>
            <div className="icon-box wow fadeInUp" data-wow-delay="0.2s">
              <h4 className="title">
                <NavLink to="">Focus</NavLink>
              </h4>
              <p className="description">
                Our focus is on writing high quality code and never compromise
                with security.
              </p>
            </div>
            <div className="icon-box wow fadeInUp" data-wow-delay="0.2s">
              <h4 className="title">
                <NavLink to="#">Skilled Team</NavLink>
              </h4>
              <p className="description">
                We have highly skilled Quality professionals in the areas of
                software engineering and product development, Knowledge
                Transfer, middleware development, API development and 3rd party
                software integration solutions.
              </p>
            </div>

            <div className="icon-box wow fadeInUp" data-wow-delay="0.4s">
              <h4 className="title">
                <NavLink to="">Direction</NavLink>
              </h4>
              <p className="description">
                Our right efforts in the right direction towards the problem
                drives the customer satisfaction to their business needs
              </p>
            </div>
            <div className="icon-box wow fadeInUp" data-wow-delay="0.4s">
              <h4 className="title">
                <NavLink to="">Strength</NavLink>
              </h4>
              <p className="description">
                Proper Planning, Full Dedication, Commitment, Integrity
              </p>
            </div>
          </div>

          <div className="col-lg-6 order-lg-2 order-1 wow fadeInUp">
            <h2 className="title">Skill Areas</h2>
            <p>
              We work on wide array of Technologies from Open Source
              Technologies to proprietary software
            </p>
            <ul className="about wow fadeInUp" data-wow-delay="0.4s">
              <li>
                <h4 className="title">Operating System</h4>
                <p>Linux</p>
              </li>
              <li>
                <h4 className="title">Programming Languages</h4>
                <p className="title">
                  Golang, Python, Rust, Java, PHP, C#, .NET
                </p>
              </li>
              <li>
                <h3 className="title">React Js | React Native</h3>
              </li>
              <li className="title">
                <h4 className="title">Web Technologies</h4>
                <p>
                  <a
                    href="https://golang.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Golang
                  </a>{" "}
                  - Beego, Fiber, Gin
                </p>
                <p className="title">
                  <a
                    href="https://www.php.net/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PHP
                  </a>{" "}
                  -{" "}
                  <a
                    href="https://symfony.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Symfony{" "}
                  </a>
                  ,{" "}
                  <a
                    href="https://laravel.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Laravel
                  </a>
                </p>
                <p className="title">
                  <a
                    href="https://www.python.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Python
                  </a>{" "}
                  -{" "}
                  <a
                    href="https://www.djangoproject.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Django
                  </a>
                  ,{" "}
                  <a
                    href="https://flask.palletsprojects.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Flask
                  </a>
                </p>
              </li>
              <li>
                <h4 className="title">Web Server</h4>
                <p>Apache2 Nginx</p>
              </li>
              <li>
                <h4 className="title">Databases</h4>
                <p>MySql , PostgreSql, SQL Server</p>
              </li>
              <li>
                <h4 className="title">Customized Solution</h4>
                <ul>
                  <li>
                    <i className="fa fa-caret-right"></i>
                    Web based CRM (Customer Relationship Management)
                  </li>
                  <li>
                    <i className="fa fa-caret-right"></i>
                    Web based MIS (Management Information System)
                  </li>
                  <li>
                    <i className="fa fa-caret-right"></i>
                    Intranet Software for Hospitals
                  </li>
                  <li>
                    <i className="fa fa-caret-right"></i>
                    Web based Software for School
                  </li>
                  <li>
                    <i className="fa fa-caret-right"></i>
                    Web based Software for Domain and Hosting Management
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
