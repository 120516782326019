import "./App.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";

import Footer from "./component/Footer";
import Header from "./component/Header";
import Home from "./component/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
// import Login from "./pages/Login";

function App() {
  return (
    <>
      <Header />

      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/services" element={<Services />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route exact path="/portfolio" element={<Portfolio />}></Route>
        {/* <Route exact path='/login' element={<Login />}></Route> */}
        {/* <Route exact path='/contact' element={<Contact />}></Route> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
